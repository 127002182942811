.comments-App{
    width: 100vw;
    height: 100vh;
    background: white !important;
    overflow: hidden !important;
    display: flex;
    margin: 0;
    padding: 0;
    background-image: url('../../images/back_circuit_gray.svg') !important;
    background-size: cover;
}   

.comments-main{
    width: 100%;
    height: 90vh;
    overflow: scroll;
    -ms-overflow-style: none; /* 익스플로러, 앳지 */
    scrollbar-width: none; /* 파이어폭스 */
}
.comments-main::-webkit-scrollbar{
    display: none;;
}
.comments-main-header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.81rem;
}
.comments-main-title{
    color: #333;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem; /* 116.667% */
}
.comments-dropdown{
    color: #333;
    text-align: right;
    font-family: Spoqa Han Sans Neo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
    /* display: flex; */
    display: none;
}

.comments-card-wrapper{
    width: 17.25rem;
    height: 23rem;
    flex-shrink: 0;
    border-radius: 2.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
    overflow: hidden;
}
.comments-card-top{
    height: 50%;
    overflow: hidden;
    position: relative;
}
.comments-card-top-img{
    width: 80%;
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translate(-50%,0);
}

.comment-result-table-title{
    background-color: rgb(246, 246, 246);
    border-radius: 0.5rem;
    display: inline-block;
    padding: 0.25rem 1.25rem;
    font-weight: 600;
}
.comment-result-table{
    width: 100%;
    margin-top: 0.25rem;
}
.comment-result-table td{
    text-align: center;
}

.RED{
    background: #F5D8D2;
}
.ORANGE{
    background: #FBE1CC;
}
.YELLOW{
    background: #FFF6CC;
}
.YELLOW img{
    width: 50%;
}
.GREEN{
    background: #CCEBD8;
}
.BLUE{
    background: #CCDCED;
}
.PURPLE{
    background: #E1D2E3;;
}
.recording-background-comment{
  position: absolute;
  bottom: 4rem;
  left: 45%;
  transform: translate(-50%,0);
  display: none;
}

.comments-card-bottom{
    height: 50%;
    background-color: white;
}
.comments-card-week{
    margin-top: 1.25rem;
    margin-left: 2.5rem;
    color: #121212;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.75rem; /* 140% */
}
.comments-card-submitDate{
    margin-top: 0.25rem;
    margin-left: 2.5rem;
    color: #333;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.comments-card-btn-wrapper{
    width: 100%;
    padding: 0 2.5rem;
    margin-top: 1.62rem;
}
.comments-card-viewFeedback{
    border: 0;
    border-radius: 0.5rem;
    background: #F1E9F2;
    width: calc(100% - 5rem);
    padding: 0.75rem;
    color: #772481;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}
.comments-card-viewFeedback-before{
    border: 0;
    border-radius: 0.5rem;
    background: #CCC;
    width: calc(100% - 5rem);
    padding: 0.75rem;
    color: #FFF;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
}
.comments-card-viewFeedback:hover{
    filter: brightness(0.96);
}

.comments-main-body{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 1.87em;
}
@media (max-width: 600px) {
    .comments-main-body{
        display: block;
    }
    .comments-card-wrapper{
        display: flex;
        width: 98%;
        height: 12rem;
        margin-top: 1rem;
    }
    .comments-card-top{
        height: 100%;
        width: 190px;
    }
    .comments-card-top-img{
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .comments-card-viewFeedback{
        margin-top: 1rem;
    }
}
@media (max-width: 400px) {
    .comments-card-top{
        height: 100%;
        width: 40%;
    }
}

.comments-main-footer{
    width: 100;
    margin-top: 2rem;
}
.comments-pagination{
    width: 100%;
    display: flex;
    gap:0.87rem;
    justify-content: center;
}
.comments-pagination-page{
    width: 1.625rem;
    height: 1.625rem;
    flex-shrink: 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
    text-align: center;
    margin-bottom: 5rem;
} 
/* <div className="comments-pagination-page">2</div> */
.comments-pagination-page-selected{
    width: 1.625rem;
    height: 1.625rem;
    flex-shrink: 0;
    border-radius: 0.8125rem;
    color: #FFF;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
    text-align: center;
    background: #333;
}

.comments-modal{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    background-image: url('../../images/back_circuit_comment.svg') !important;;
    background-size: cover;
    display: none;
}
.wrapper-comment-wrapper{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}
.comment-icon-close{
    position: absolute;
    left: 3rem;
    top : 3rem;
}

.comment-detail-main{
    position: absolute;
    left: 53%;
    transform: translate(-50%,0);
    height: 100%;
}
.comment-body{
    position: relative;
    text-align: left;
}
.comment-detail-section1{
    margin-top: 5rem;
}
.comment-detail-section1-title{
    display:inline-block;
    color: #FF6F09;
    font-family: NanumSquareRoundOTF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5rem; /* 120% */
    padding: 0.75rem 1.5rem;
    border-radius: 2.75rem;
    background: rgba(255, 111, 9, 0.10);
}
.comment-detail-section1-info{
    margin-top: 1rem;
    color: #333;
    text-align: left;
    font-family: NanumSquareRoundOTF;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; /* 177.778% */
}
.comment-detail-section2-title{
    display:inline-block;
    color: #FF6F09;
    font-family: NanumSquareRoundOTF;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 800;
    line-height: 1.5rem; /* 120% */
    padding: 0.75rem 1.5rem;
    border-radius: 2.75rem;
    background: rgba(255, 111, 9, 0.10);
    margin-top: 1.0rem;
    width: auto;
}
.comment-detail-section2-info{
    margin-top: 0rem;
    color: #333;
    text-align: left;
    font-family: NanumSquareRoundOTF;
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem; /* 177.778% */
}
.go-to-click{
    position: absolute;
    right: 20%;
    bottom: 10%;
}
.go-to-click:hover{
    filter: brightness(1.1);
}
.comment-detail-ranger-wrapper{
    position: absolute;
    bottom: 0;
    right: 0;
}
.comment-ranger-text{
    color: #121212;
    text-align: center;
    font-family: NanumSquareRoundOTF;
    font-size: 0.8rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.125rem; /* 150% */
    padding: 0.75rem 1.5rem;
    border-radius: 5rem;
    background: #F9F9FA;
    margin-bottom: 0.5rem;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}

.clinic-body{
    display: none;
}

.agree-body{
    display: none;
}
@media (max-width: 1179px) {
    .comment-icon-close{
        z-index: 3;
    }
    .comment-detail-main {
        left: 50%!important;
    }
    .comment-body{
        margin-top: 14vh;
        width: 100% !important;
        height: 57vh;
        overflow-x:hidden;overflow-y:scroll;
        margin-left:3%;
    }
    .comment-detail-body-bottom{
        width: 100%!important;
    }
    .comment-detail-section1{
        margin-top: 0 !important;
    }
    .comments-App .comment-detail-section1-title{
        margin-top: 0 !important;
    }
    .comments-App .console-btn-box{
        bottom: 30% !important
    }
    .comments-App .console-pagnation{
        top: 53% !important;
    }
    .comments-App .recording-background-comment{
        bottom:31.2% !important;
        width: 100px;
        height: 100px;
        left: 38%;
    }
    .agree-body{
        width: 70vw;
    }
    .agree-text{
        margin-top: 8rem !important;
        width: 100% !important;
        padding-top: 2rem;
        padding-bottom: 2rem;
        padding-left: 2% !important;
        padding-right: 2% !important;
        border-radius: 3rem !important;
        font-size: 1.5rem !important;
        line-height: 2.5rem !important;
    }
    .clinic-sentence span{
        font-size: 2rem !important;
        line-height: 2rem !important;
    }
    .comment-readingranger{
        height: 160px !important;
        margin-left: 30%;
    }
    .agree-ranger{
        top:0%;
        left: initial !important;
        height: 160px !important;
        right:0% !important;
    }
    .agree-btn-wraaper{
        margin-top: 4rem !important;
    }
    .agree-btn{
        margin: 0 0.5rem !important;
    }
    .agree-btn-text{
        font-size: 1.5rem !important;
    }
    .agree-baby{
        height: 160px !important;
    }
}

.agree-text{
    margin-top: 7.5rem;
    width: 44.625rem;
    height: 16.875rem;
    flex-shrink: 0;
    border-radius: 5rem;
    background: #F9F9FA;
    color: #333;
    text-align: center;
    font-family: GangwonEduPower;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem; /* 142.857% */
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
}
.agree-ranger{
    position: absolute;
    left: -28%;
    height: 220px;
}
.agree-baby{
    position: absolute;
    right: 2rem;
    height: 220px;
    bottom: 2rem;
}
.agree-btn-wraaper{
    margin-top: 6.94rem;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
}
.agree-btn{
    width: 180px;
    height: 80px;
    margin: 0 1.25rem;
    text-align: center;
    display: flex;
    align-items: center;
    background-image: url('../../images/agree_btn.svg');
    background-repeat: no-repeat;
}
.agree-btn:hover{filter: brightness(0.9);}
.agree-btn-text{
    width: 175px;
    color: #333;
    font-family: GangwonEduPower;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.5rem; /* 142.857% */
}


.comment-clinic-play-music:hover{
filter: brightness(1.1);
}