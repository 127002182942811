@font-face {
  font-family: "NanumSquareRoundOTF";
  font-style: normal;
  font-weight: 500;
  src: url("./fonts/NanumSquareRoundOTFR.woff") format("woff");
}
@font-face {
  font-family: "GangwonEduPower";
  font-style: normal;
  font-weight: 400;
  src: url("./fonts/GangwonEduPower.woff") format("woff");
}
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);

body{
    width: 100vw;
    height: 100vh;
    background: #F2EAF3 !important;
    overflow: hidden !important;
    display: flex;
    margin: 0;
    padding: 0;
    background-image: url('./images/back_circuit.svg') !important;
    background-size: cover;
}

.full_wrapper{
    width: 74.62rem;
    height: 100vh;
    position:absolute;
    left: 50%;
    transform: translate(-50%,0);
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none
}
@media (max-width: 1237px) {
    .full_wrapper{
        width: 98% !important;
    }
    .wrapper-comment-wrapper{
      width: 100%;
    }
    .comment-page-comment-modal-wrapper{
      width: 100%;
      height: 90vh !important;
      object-fit: fill;
      ;
    }
    .go-to-click{
      left: 20%;

    }
}
@media (max-width: 857px) {
  .comment-page-comment-modal-wrapper{
      width: 100%;
      height: 100vh !important;
      object-fit: fill;
      ;
    }
    .comment-table-cover{
      padding: 0 !important;
      margin-left: 0 !important;
      width: 160% !important;
    }
    .comment-table-cover div{
      margin-left: 0 !important;
    }
    .comment-table-cover table{
      width: 100%;
    }
    .comment-detail-section1-title{
      font-size: 1.2rem !important;
      margin-top: 3rem;
    }
    .comment-detail-section2-title{
      font-size: 1.2rem !important;
    }
    .comment-detail-section1-info {
      font-size: 1.0rem !important;
    }
    .comment-detail-section2-info {
      font-size: 1.0rem !important;
    }
    .comment-detail-ranger-wrapper img{
      height: 180px;
    }
}
header{
    width: 100%;
    height: 5rem !important;
    position: relative;
    padding-top:0.94rem;
    display:flex;
    justify-content: space-between;
}

main{
    width: 100%;
    position: absolute;
    bottom: 50%;
    transform: translate(0,50%);
}

.sideBar {
  position: absolute;
  right:0;
  top:0.8rem;
  width: 12.3125rem;
  height: 20.75rem;
  flex-shrink: 0;
  border-radius: 1rem;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
  z-index:5;
  display: none;
  overflow: hidden;
}
.sideBar:hover{
  display: block !important;
}
.user-icon:hover + .sideBar{
  display: block !important;
}
.sideBar-menu{
  color: #333;
  font-family: Spoqa Han Sans Neo;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
  padding: 0.87rem 1.5rem;
}
.sideBar-top{
  margin-top: 75px;
}
.sideBar-menu:hover{
  background: #F2EAF3;
}
.user-info-wrapper{
  display: block;
}
.user-info{
  display: flex;
  float: left;
  width: 100%;
  padding: 0.87rem 0 0.88rem 1.2rem;
}
.user-name{
  color: #333;
  font-family: Spoqa Han Sans Neo;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.75rem; /* 140% */
  margin-top:0.12rem;
}
.weekNuser-wrapper{
  margin-left: 0.75rem;
  padding-top:0.27rem;
}
.week-info{
  color: #772481;
  font-family: Spoqa Han Sans Neo;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1rem; /* 133.333% */
}



