.countdown-number{
  position: absolute;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
  color: #FFF;
  text-align: center;
  font-family: NanumSquareRoundOTF;
  font-size: 10rem;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: none;
  z-index: 4;
}

#record{
  display: none;
}
.audio-container{
  position: absolute;
  bottom:0;
  left:50%;
  transform: translate(-50%,120%);
}
.music-container{display: none;}

#spinner {
  margin: calc(50% - 25px) auto;
  width: 70px;
  height: 70px;
  border: 7px solid rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  border-top-color: black;
  border-radius: 100%;
  animation: spin 1s ease-in-out infinite;
}
.spinner-wrapper{
  position: absolute;
  z-index: 5;
  top:45%;
  left:50%;
  transform: translate(-50%,-50%);
  display: none;
}

@keyframes spin {
  100%
  {
    transform: rotate(360deg);
  }
}


.console-cover{
  position: absolute;
  z-index: 0;
}
.console-pagnation{
  position: absolute;
  /* top: 4rem; */
  top:10%;
  left: 50%;
  transform: translate(-50%,0);
  display: flex;
}
.console-page{
  width: 1.5rem;
  height: 1.5rem;
  background: #F2F3F5;
  border-radius: 50%;
  margin:0 0.625rem;
  color: #FFF;
  text-align: center;
  font-family: NanumSquareRoundOTF;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: 1.5rem; /* 150% */
}
.console-page-selected{
  background: #772481;
}

.console-msg-box{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%,-50%);
  color: #333;
  text-align: center;
  font-family: GangwonEduPower;
  font-size: 3.125rem;
  font-style: normal;
  font-weight: 400;
  /* line-height: 150%;  */
}
.aitest-sub-text{
  font-size: 1.5rem;
}

.console-wrapper .icon-close{
    position: absolute;
    top: 1%;
    left: 6%;
    z-index: 4;
}
@media (max-width: 1500px) {
    .aitest-main-body{
        height: 80vh;
    }
    .console-box{
      height: 80vh;
    }
}
@media (max-width: 1237px) {
    .aitest-main-body{
        height: auto;
    }
    .console-box{
      height: auto;
    }
    .console-wrapper{
        width: 98% !important;
        /* height: 80vw; */
    }
    .console-msg-box{
      width: 80%;
    }
}

@media (max-width:915px){
  .console-msg-box{
      font-size: 2rem !important;
  }
  .aitest-sub-text{
  font-size: 1rem;
}
  .btn-img-wrapper img{
    width: 60px;
    height: 60px;

  }
  .console-wrapper .icon-close{
    width: 60px;
    height: 60px;
    left: 4% !important;
  }
}
.aitest-main-body .detail-ranger-wrapper{
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 99;
}

@media (max-width:790px){
  /* .console-pagnation{
    top: 4rem;
  } */
  .aitest-scroll{
    overflow: scroll;
    /* height: 85vh; */
  }
  .aitest-main-body .console-wrapper{
    height: auto;
  }
  .aitest-main-body .detail-ranger-wrapper{
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    bottom: -220%;
  }
  .aitest-main-body .detail-ranger-wrapper .comment-readingranger{
    height: 230px !important;
    margin-left: initial;
  }
  .aitest-main-body .detail-ranger-wrapper .comment-ranger-text{
    font-size: 1.5rem;
    line-height: 1.5rem;
    width: 220px;
    padding: 1.3rem;
  }
  .console-msg-box{
    top:50% !important;
  }
  .countdown-number{
    font-size: 5rem !important;
  }
  .console-msg-box{
      font-size: 2rem !important;
  }
  .btn-img-wrapper img{
    width: 60px;
    height: 60px;

  }
  .console-wrapper .icon-close{
    width: 40px;
    height: 40px;
    left: 4% !important;
  }
  .console-btn-box{
    bottom:-60% !important;
  }
  .audio-container{
    left: 60% !important;
    transform: translate(-50%,0);
    bottom: -85% !important;
  }
  .recording-background{
    bottom:-55% !important;
    width: 100px;
    height: 100px;
  }
  main{
    position:relative;
    bottom: initial;
    transform: initial;
  }
  .aitest-main-body{
    margin-top: 2rem;
  }
}
@media (max-width:481px){
  .console-msg-box{
      font-size: 1.6rem !important;
  }
  
}

.console-btn-box{
  position:absolute;
  bottom: 4rem;
  display: flex;
  width: 100%;
  justify-content: center;
}
.btn-name{
  margin-top: 0.5rem;
  text-align: center;
}
.btn-set{
  margin: 0 1.25rem;
}
.btn-img-wrapper{
  overflow: hidden;
}
.btn-img-wrapper img:hover{
  /* transform: translate(5%,5%); */
  filter: brightness(1.08);
}

.recording-background{
  position: absolute;
  bottom: 4rem;
  left: 50%;
  transform: translate(-50%,0);
  display: none;
}
.record-audio{
  display: none;
}
