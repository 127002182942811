.Error-Page-Main-wrapper{
    display: flex;
    justify-content: center !important;
    height: 80vh;
    margin-top: 80px;
    overflow: scroll;
    -ms-overflow-style: none; /* 인터넷 익스플로러 */
    scrollbar-width: none; /* 파이어폭스 */
}
.Error-Page-Main-wrapper::-webkit-scrollbar {
  display: none;
}
.Error-Page-Main{
    background-color: #6C0E77;
    width: 600px;
    position: relative;
}
.Error-section-back{
    margin: 0 20px 0 20px;
    background-color: white;
    height: 100%;
}
@media (max-width: 600px) {
    .Error-Page-Main{
        width: 100vw !important;
    
    }
    .Error-line-center{
        margin-top: 0!important;
    }
    .Error-Page-Main-wrapper{
        margin-top:0;
        height:100%
    }
    .Error-title{
        font-size: 30px !important;
    }
    .Error-line{
        font-size: 15px !important;
    }
    .Error-Content{
        height: 100% !important;
    }
    .App:has(.Error-Page-Main-wrapper){
        height: 100%;
    }
}
#root:has(.Error-Page-Main-wrapper){
    width: 100vw;
}
.Error-Content{
    /* transform: translate(-50%,-50%); */
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: calc(100%-80px);
    padding-left: 40px;
    padding-right: 40px;

}
.Error-title{
    width: 100%;
    text-align: center;
    margin-bottom: 60px;
    font-size: 40px;
    font-weight: 700;
}
.Error-line{
    font-size: 20px;
    margin-bottom: 20px;
}

.Error-line-center{
    font-size: 20px;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
    margin-top: 50px;
}
.Error-line b{
    color: red;
}
.Error-line-real{
    margin-top: 10px;
    margin-bottom: 40px;
    border: #a383a7 solid 0.2px;
}