body{
    width: 100vw;
    height: 100vh;
    background: #FFF;
    overflow: hidden !important;
    display: flex;
    margin: 0;
    padding: 0;
}

.login-background{
    background-color: white !important;
}
.login-container{
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    z-index: 5;
}

.logo{
    width: 20.375rem;
    height: 11.3125rem;
    flex-shrink: 0;
}

.login_group{
    width: 23.625rem;
}

@media (max-width: 448px) {
    .login-container{
        width: 100%;
    }
    .logo{
        width: 70%;
        height: auto;
    }
    .login_group{
        width: 80%;
}
}

.login_group input{
    width: 92%;
    height: 2.75rem;
    margin-top: 1.5rem;
    border-radius: 1.75rem;
    background: #F9F9FA;
    border: 0;
    padding-left: 25px;
}
.login_group .info-text{
    margin-top: 0.75rem;
}
.login_group span{
    margin-left: 1.5rem;
    color: #B00020;
    /* font-family: Spoqa Han Sans Neo; */
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5rem; /* 171.429% */
}
.login_group .login-btn{
    width: 100%;
    height: 2.75rem;
    border-radius: 1.75rem;
    margin-top: 1.5rem;
    background: #7C221E;
    border: 0;
    color:white;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
}
.login_group .login-btn:hover{
      filter: brightness(120%);
    }   

/* 
footer image css
*/
@media (min-width: 1920px) {
    .bottom-wrapper{
      width: 100vw !important;
    }
    .bottom-img{
      width: 100vw;
      height: 20vw !important;
    }
    #root{
      width:100vw
    }
}
.bottom-wrapper{
    width:100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
}
.bottom-img{
    position:absolute;
    left: 50%;
    transform: translate(-50%, 0) !important;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
}
.bottom-left{
    position:absolute;
    left: 0;
    width: 50%;
    height: 100vh;
    overflow: hidden;
}
.bottom-right{
    position:absolute;
    right: 0;
    width: 50%;
    height: 100vh;
    overflow: hidden;
}



.login-resource1{
    position: absolute;
    bottom: -2.62rem;
    left:31.55rem;
    width: 19.3125rem;
    height: 18.3125rem;
    overflow: hidden;
    flex-shrink: 0;
    opacity: 0.699999988079071;
    mix-blend-mode: luminosity;
    z-index: 2;
}
.login-resource2{
    position:absolute;
    left: -8.19rem;
    bottom: -1.62rem;
    width: 39.375rem;
    height: 30.375rem;
    flex-shrink: 0;
    opacity: 0.800000011920929;
    mix-blend-mode: screen;
    z-index: 2;
}
.login-resource3{
    position:absolute;
    right:3.5rem;
    bottom:7.75rem;
    width: 29.0625rem;
    height: 10.3125rem;
    flex-shrink: 0;
    opacity: 0.20000000298023224;
    mix-blend-mode: screen;
    z-index: 2;
}
.login-resource4{
    position:absolute;
    right:17.19rem;
    bottom:0rem;
    width: 31.3125rem;
    height: 9.25rem;
    flex-shrink: 0;
    z-index: 2;
    mix-blend-mode: luminosity;
}
.login-resource5{
    position:absolute;
    right: 0;
    bottom:0;
    width: 60.75rem;
    height: 57.875rem;
    flex-shrink: 0;
    mix-blend-mode: color-burn;
    z-index: 1;
}
.login-resource6{
    position:absolute;
    left: 0;
    bottom:0;
    width: 60.75rem;
    height: 57.875rem;
    flex-shrink: 0;
    mix-blend-mode: color-burn;
    z-index: 1;
}

