main .row{
    display:flex;
    justify-content: space-between;
}
.main-menu, .sub-menu {
    width: 49%;
    background-color: white;
    flex-shrink: 0;
    border-radius: 2.5rem;
    background: #FFF;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
    display:flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}

.main-menu{
    height: 36.375rem;
}
@media (max-width: 1500px) {
    .main-menu{
        height: auto ;
        padding-top:  8%;
        padding-bottom: 5%;
    }
}
@media (max-width: 1237px) {
    .main-menu{
        height: auto ;
        padding-top:  8%;
        padding-bottom: 6%;
    }
}
@media (max-width: 782px) {
    .main-menu img{
        width: 70%;
    }
    .main-menu .icon-slider img{
        width: 100%;
    }
    .main-menu .icon-slider {
        width: 100%;
    }
    .icon-slider-wrapper{
        width: 70% !important;
    }
}


.main-menu:hover{
    box-shadow: 0px 0px 10px 0px rgb(169, 167, 167);
}
.sub-menu{
    height: 7rem;
    margin-top: 2%;
}
.sub-menu:hover{
    box-shadow: 0px 0px 10px 0px rgb(169, 167, 167);
}
.main-menu .menu-text{
    margin-bottom: 3.56rem;
}
.menu-text{
    color: #121212;
    text-align: center;
    font-family: Spoqa Han Sans Neo;
    font-size: 2.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 3rem; /* 120% */
}
.main-page main{
    overflow: scroll;
    height: 85vh;
    position: relative;
    bottom : initial;
    transform: initial;
    -ms-overflow-style: none; /* 익스플로러, 앳지 */
    scrollbar-width: none; /* 파이어폭스 */
    margin-bottom: 10rem;
}
.main-page main::-webkit-scrollbar{
    display: none;;
}
@media (max-width: 967px) {
    .menu-text {
        font-size: 1.8rem;
    }
    .row2{
        margin-bottom: 10rem;
    }
}
.icon-slider {
    display:flex;
    width: 279px;
    animation: loop 10s linear infinite;
}
.icon-slider-wrapper{
    overflow: hidden;
}

@media (max-width: 574px) {
    main .row{
        display:flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .main-menu{
        margin-top: 2%;
        
    }
    .main-menu, .sub-menu {
        width: 95%;
        background-color: white;
        flex-shrink: 0;
        border-radius: 2.5rem;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
        display:flex;
        align-items:center;
        justify-content: center;
        flex-direction: row-reverse;
        
    }
    .menu-text{
        font-size: 1.2rem;
        margin-bottom: 0 !important;
    }
    .main-menu img{
        width: 20% ;
        margin-right: 40px;
    }
    .main-menu .icon-slider-wrapper{
        width: 20% !important;
        margin-right: 40px;
    }
    .main-menu .icon-slider-wrapper img{
        margin-right: 0;
    }
}




@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  32%{
    transform: translateX(0%);
  }
  33%{
    transform: translateX(-100%);
  }
  65%{
    transform: translateX(-100%);
  }
  66%{
    transform: translateX(-200%);
  }
  98%{
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(0%);
  }
}
@-webkit-keyframes loop {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-33%);
  }
}
